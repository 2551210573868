.header{
    color: #fff;
    position: fixed;
    text-align: left;
    top: 0;
    width: 100%;
    height:50px;
    background-color: black;
    padding-top: 5px; /* Adjust the top padding as needed */

  }

.logoo{
    height: 100%;
    
    float: left;
    Padding: 5px, 5px, 5px, 5px;

}

nav {
    display: inline-block;
    margin-left: 20px;
}

nav a {
    color: #fff;
    position: relative;
    text-decoration: none;
    margin: 0 20px; /* Increase spacing between links */
    font-size: 20px; /* Make text bigger */
    margin-top: -15px
}

nav a:hover {
    text-decoration: underline;
}