input {
  margin-bottom: 10px;
  padding: 12px;
  font-size: 16px;
  width: 100vw;
  color: white;
}
body {
  color: white;
}
.dropdown-menu li {
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.6) !important;
  list-style-type: none !important;
  cursor: pointer !important;
  
}
button {
  padding: 8px 16px;
  font-size: 16px;
  background-color: transparent; 
  color: #fff;
  border: 2px solid #fff; 
  cursor: pointer;
  position: relative;
  border-radius: 30px; 
}



.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: 100vh; 
  overflow-y: auto; 
}

body {
  background-color: #000000;
  overflow: hidden;
  position: relative;
}



.content {
  position: relative;
  z-index: 1;
}
.transparent-input input {
  background-color: transparent !important;
  border-radius: 10px !important;
  border: 1px solid rgba(255, 255, 255, 0.6) !important;
}
.displayed-text {
  margin-top: 20px;
  text-align: center;
  color: white;
}
.input-textarea {
  resize: none;
  width: 65lvw;
  min-height: 100px;
  padding: 16px;
  margin: 0 0 1em;
  margin: 0 auto;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  color: white;

  
}

.clear-button {
  background-color: crimson;
  color: #fff;
  margin-top: 10px;
}

.clear-button:hover {
  background: linear-gradient(45deg, #9500f2, #5500ff);
}

.submit-button {
  background: linear-gradient(45deg, #001ac1, #050098);
  border-radius: 20px;
  padding: 10px 20px;
  margin-top: 10px;
  align-items: right;
  align-self: right;
  justify-content: right;
  align-content: right;
  align-items: right;
  box-shadow: 0 0 40px 3px #8c00ff;
  color: #fff;
  align-items: right;
  position: relative;
}
.submit-button:hover {
  background: linear-gradient(45deg, #312aff, #0b23c0);
  border-radius: 20px;
  padding: 10px 20px;
  box-shadow: 0 0 40px 20px #8c00ff;
  color: #fff;
  position: relative;
}



.loading-indicator {
  height: 10px;
  width: 100px;
  background-color: #1d007e;
  margin-top: 14px;
  box-shadow: 0 0 40px 15px #2b00ae;
  margin-bottom: 14px;
  animation: loadingAnimation 2s infinite;
}

@keyframes loadingAnimation {
  0% { width: 0%; }
  25% { width: 25%; }
  50% { width: 50%; }
  75% { width: 25%; }
  100% { width: 0%; }
}

.submit-button-container {
  text-align: right;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown-menu li {
  list-style-type: none !important;
}
.dropdown-container,
.submit-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style-type: none !important;
  cursor: pointer !important;
}


.dropdown-item {
  list-style-type: none !important;
}
ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}
.typewriter-container {
  width: max-content;
}
.typing-effect {
  font-family: monospace;
  white-space: nowrap;
  overflow: hidden;
  border-right: .15em solid white; 
  animation: typing 2s steps(80, end), blink-caret .75s step-end infinite;
}

.donate {
  background-color: black;
  margin-bottom: 15px;
  padding-left: 150px;
  font-size: 18px;
  width: 80vw;
  color: white;
  justify-content: center;
}

.head {

  text-align: center;
  height: 10vh;
}

.lst {
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 0;
  padding-left: 100px;
  padding-right: 100px;

}

.submit-button-container p {
  margin-right: 10px;
}
.char-limit-exceeded {
  color: red;
}

/* Base */

hh1 {
  color: #474747;
  font-size: 50px;
  font-weight: bold;
  font-family: monospace;
  letter-spacing: 7px;
  cursor: pointer;
}

hh1 span {
  transition: 0.5s ease-out;
}

hh1:hover span:nth-child(1) {
  margin-right: 35px;
}

hh1:hover span:nth-child(1):after {
  margin-left: 30px;
}



hh1:hover span {
  color: #fff;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
}
